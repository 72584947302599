<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        สต๊อกสินค้า
      </v-card-title>
    </v-card>

      <!-- แก้ไขสต๊อกสินค้า -->
      <v-dialog v-model="editstock_dialog" scrollable max-width="45%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1">
        <v-card-title class="height-57">
          <span>แก้ไข</span>
          <v-spacer/>
          <v-btn icon @click="editstock_dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-x-auto">
          <v-form ref="editStockForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="3" >
              ชื่อสินค้า :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.name"
                @change="value => editstock.name = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              รหัสสินค้า :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.productCode"
                @change="value => editstock.productCode = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              SKU :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.SKU"
                @change="value => editstock.SKU = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              Barcode :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.barcode"
                @change="value => editstock.barcode = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              CF Code :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.cfCode"
                @change="value => editstock.cfCode = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              ลักษณะ1 :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.prop1Description"
                @change="value => editstock.prop1Description = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              ลักษณะ2 :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.prop2Description"
                @change="value => editstock.prop2Description = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              จำนวน Buffer :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.buffer"
                @change="value => editstock.buffer = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" >
              RackNo :
            </v-col>
            <v-col cols="12" md="9" >
              <v-text-field
                :value="editstock.rackNo"
                @change="value => editstock.rackNo = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
         
         
           
          <v-row >
            <v-col cols="12"  style="display:flex; flex-direction: row-reverse;">
              <v-btn :elevation="1" color="primary" class="px-2" @click="handleUpdateStock()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก</b></span>
              </v-btn>
            </v-col>
          </v-row>

        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- รายละเอียดสินค้าจัดเซ็ต -->
    <v-dialog v-model="dialogDetail" max-width="80%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-view-list</v-icon><span class="ml-3">ข้อมูลสินค้า</span>
          <v-spacer/>
          <v-btn color="primary" class="pa-2 mr-2" @click="UpdateStock()"><v-icon size="16" class="pr-1">fa-edit</v-icon>แก้ไข</v-btn>
          <v-btn icon @click="dialogDetail=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto pa-0">

          <!-- PC hidden-xs-only -->
          <v-data-table
            :headers="headerDetail"
            :items="datasetDetail"
            :items-per-page="9999999999"
            hide-default-footer

            class="packhai-border-table hidden-xs-only"
          >
            <template v-slot:item.no="{ item }">
              {{ formatMoney(datasetDetail.length - datasetDetail.indexOf(item)) }}
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize mt-1">
              </a>
            </template>
            <template v-slot:item.prop1="{ item }">
              <span class="fn-13">
                {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
              </span>
            </template>
            <template v-slot:item.quantity="{ item }">
              {{ formatMoney(item.quantity) }}
            </template>
          </v-data-table>

          <!-- Mobile hidden-sm-and-up -->
          <v-data-iterator
            :items="datasetDetail"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
            class="hidden-sm-and-up"
          >
            <template v-slot:default="props">
              <v-row class="ma-0">
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item">
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12"> No Picture </span>
                        </a>
                        <div class="v-list-item__content pa-2 pl-0" >

                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                ชื่อ
                              </div>
                              <div style="line-height: 1rem !important;">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="cut-text">
                                      <span v-if=" item.name != null">:  {{ item.name }}</span>
                                    </div>
                                  </template>
                                  <span v-if=" item.name != null">{{ item.name }}</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  ลักษณะ
                                </div>
                                <div class="cut-text">
                                  : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  รหัสสินค้า
                                </div>
                                <div class="cut-text">
                                  : {{ item.productCode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  SKU
                                </div>
                                <div class="cut-text">
                                  : {{ item.sku }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  Barcode
                                </div>
                                <div class="cut-text">
                                  : {{ item.barcode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  จำนวน
                                </div>
                                <div>
                                  : {{ formatMoney(item.quantity) }}
                                </div>

                              </div>

                            </div>
                          </div>

                        </div>
                      </div>


                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="pb-2">
          <v-col cols="12" md="6">
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> คลัง : </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-autocomplete
                  v-model="search.warehouse"
                  placeholder="กรุณากรอกร้านค้า"
                  :items="warehouseList_filter"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
                  return-object
                  hide-details
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> ร้านค้า :  </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-autocomplete
                  v-model="search.shop"
                  placeholder="กรุณากรอกร้านค้า"
                  :items="search.shopList"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
                  return-object
                  hide-details
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> ชื่อสินค้า : </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-text-field
                  :value="search.name"
                  @change="value => search.name = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  ref="refaddFCName"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> SKU : </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-text-field
                  :value="search.sku"
                  @change="value => search.sku = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  ref="refaddFCName"
                  outlined
                  dense hide-details
                  autocomplete="off"
                  placeholder="สามารถใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2">  บาร์โค้ด : </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-text-field
               
               :value="search.barcode"
               @change="value => search.barcode = Trim_value(value)"
               @keyup.enter="SearchStock()"
               ref="refaddFCName"
               outlined
               dense hide-details
               autocomplete="off"
               placeholder="สามารถใส่ , เพื่อค้นหาหลายรายการ"
             ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2">  รหัสสินค้า : </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-text-field
              :value="search.productCode"
              @change="value => search.productCode = Trim_value(value)"
              @keyup.enter="SearchStock()"
              ref="refaddFCName"
              outlined
              dense hide-details
              autocomplete="off"
            ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> Brand : </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-text-field
                  :value="search.brand"
                  @change="value => search.brand = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> วันที่ผลิตต่ำกว่า : </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-menu
                  ref="expire_date"
                  v-model="search.menu_mfg_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="search.date_mfg"
                      outlined
                      readonly
                      label="เลือกวัน"
                      prepend-inner-icon="mdi-calendar"
                      @blur="search.mfg_date = parseDate(search.date_mfg)"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      clearable
                      @click:clear="search.date_mfg = null"

                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="search.mfg_date" @input="search.menu_mfg_date = false" @change="search.date_mfg = formatDate(search.mfg_date)" no-title  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> วันหมดอายุต่ำกว่า : </v-col>
              <v-col class="pb-1" cols="12" md="8"  >
                <v-menu
                        ref="expire_date"
                        v-model="search.menu_expire_date"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="search.date_expire"
                            outlined
                            readonly
                            label="เลือกวัน"
                            prepend-inner-icon="mdi-calendar"
                            @blur="search.expire_date =parseDate(search.date_expire)"
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                            clearable
                            @click:clear="search.date_expire = null"

                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="search.expire_date" @input="search.menu_expire_date = false" @change="search.date_expire =formatDate(search.expire_date)" no-title  ></v-date-picker>
                      </v-menu>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> จำนวนคงเหลือ : </v-col>
              <v-col class="pb-1" cols="12" md="4"  >
                <v-select
                  v-model="search.quantityCriteriaType"
                  :items="search.quantityType"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                />
              </v-col>
              <v-col class="pb-1" cols="12" md="4">
                <v-text-field
                  :value="search.quantity"
                  @change="value => search.quantity = Trim_value(value)"
                 
                  ref="refaddFCName"
                  outlined
                  dense hide-details
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> จำนวนรอแพ็ค : </v-col>
              <v-col class="pb-1" cols="12" md="4"  >
                <v-select
                  v-model="search.quantityWaitingCriteriaType"
                  :items="search.quantityType"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                />
              </v-col>
              <v-col class="pb-1" cols="12" md="4">
                <v-text-field
                  :value="search.quantityWaiting"
                  @change="value => search.quantityWaiting = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  ref="refaddFCName"
                  outlined
                  dense hide-details
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="12" md="4" lg="3"/>
              <v-col cols="12" md="8">
                <v-btn :elevation="1" class="pa-2 mt-1" color="primary" dark @click="SearchStock(),Set_Load_Data()">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon> ค้นหา
                </v-btn>
                <vue-excel-xlsx
                    id="GetExcel"
                    v-show="false"
                    :data="datasetExcel"
                    :columns="headerExcel"
                    :file-name="'mystock'"
                    :sheet-name="'shee1'"
                    >
                </vue-excel-xlsx>
                <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="ExportExcel(search)">
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                  <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
       

    
   
 
      </v-card-text>
    </v-card>

     <!-- ข้อมูลทั้งหมด -->
    <v-row class="ma-0 py-1"  justify="space-between" align="center">
      <v-col cols="8"  class="pa-0 ">
       <table>
            <td  >
            <span class="head-doc"> สินค้า </span> ทั้งหมด {{ formatMoney(total) }} แถว
           </td>
          <!-- <td class="p-15  w-13r" >
            <v-select
             v-model="selectItemFrom.selectItemBy"
              :items="selectItemFrom.selectItemByList"
              persistent-hint
              return-object
              dense hide-details
              outlined
              style="background: #fff;"
              item-text="name"
              item-value="id"
              label="จัดเรียงโดย"

            ></v-select>
          </td>
          <td class="p-15  w-13r">
            <v-select
             v-model="selectItemFrom.selectItemSort"
              :items="selectItemFrom.selectItemSortList"
              persistent-hint
              return-object
              dense hide-details
              outlined
              style="background: #fff;"
              item-text="name"
              item-value="id"
               label="เรียงตาม"
            ></v-select>
          </td>

          <td class="p-15  w-13r">
             <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined v-bind="attrs" v-on="on" >
                <span class="pl-2">ดำเนินการ</span>
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="printStock('bacode')">
                <v-list-item-title>ปริ้นบาร์โค้ด</v-list-item-title>
              </v-list-item>
              <v-list-item @click="printStock('info')">
                <v-list-item-title>ปริ้นรายละเอียดสินค้า</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          </td> -->


       </table>


      </v-col>
      <v-col cols="2"  class="pa-0  hidden-xs-only  " align="right" >
        <!-- <v-btn   color="" class="pa-2 ml-2 mt-1"  icon @click="changeFormat_DataTabel()">
              <v-icon v-if="!formatDataTabel" size="16" class="pr-1">fa-table</v-icon>
              <v-icon v-else size="16" class="pr-1">fa-list</v-icon>

        </v-btn> -->
      </v-col>
    </v-row>
    <v-row class="ma-0 py-1"  justify="space-between" align="center">
      <v-col cols="8"  class="pa-0 ">
       <table>
           <td>
             <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined v-bind="attrs" v-on="on" color="primary"  :disabled="selectedStock.length == 0">
                <span class="pl-2">ดำเนินการ </span>
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list >
              <v-list-item @click="ontheClick('bacode')">
                <v-list-item-title>ปริ้นบาร์โค้ด</v-list-item-title>
              </v-list-item>
              <v-list-item @click="ontheClick('info')">
                <v-list-item-title>ปริ้นรายละเอียดสินค้า</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          </td>

          <td class="p-15  w-13r" >
            <v-select
             v-model="selectItemFrom.selectItemBy"
              :items="selectItemFrom.selectItemByList"
              persistent-hint
              return-object
              dense hide-details
              outlined
              style="background: #fff;"
              item-text="name"
              item-value="id"
              label="จัดเรียงโดย"

            ></v-select>
          </td>
          <td class="p-15  w-13r">
            <v-select
             v-model="selectItemFrom.selectItemSort"
              :items="selectItemFrom.selectItemSortList"
              persistent-hint
              return-object
              dense hide-details
              outlined
              style="background: #fff;"
              item-text="name"
              item-value="id"
               label="เรียงตาม"
            ></v-select>
          </td>




       </table>


      </v-col>
      <v-col cols="2"  class="pa-0  hidden-xs-only  " align="right" >
        <v-btn   color="" class="pa-2 ml-2 mt-1"  icon @click="changeFormat_DataTabel()">
              <v-icon v-if="!formatDataTabel" size="16" class="pr-1">fa-table</v-icon>
              <v-icon v-else size="16" class="pr-1">fa-list</v-icon>

        </v-btn>
      </v-col>
    </v-row>



    <!-- แสดงตาราง -->
    <!-- PC hidden-xs-only -->
    <v-data-table
       v-model="selectedStock"
      :headers="showHeaders"
      :items="dataset"
      :items-per-page="9999999999"
      item-key="id"
      hide-default-footer
      :loading="tableloading"
      :single-select="false"
      show-select
      class="packhai-border-table hidden-xs-only"
    >
      <template v-for="(header, index) in header" v-slot:[`header.${header.value}`]="{ header }">
        <v-tooltip :key="index" v-slot:activator="{ on }">
          <span> {{header.text == "ชื่อ" && !formatDataTabel? "รายละเอียดเบื้องต้น" : header.text}}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.no="{ item }">
        {{ formatMoney((total-dataset.indexOf(item))-offsets) }}
      </template>
      <template v-slot:item.editstock="{ item }">
                <a @click="OpenDialogEditStock(item)"><v-icon size="16" color="red darken-3">fa-edit</v-icon></a>
              </template>
      <template v-slot:item.photoLink="{ item }">
        <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
          <img :src="item.photoLink" class="picsize mt-1">
        </a>
        <a v-else>
               <img src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/WhiteImage.JPG" class="picsize mt-1">
        </a>

      </template>
      <template v-slot:item.name="{ item }">
        <a  @click="gotoStock_InFo(item)">

          <v-row>
             <v-col cols="12" align="left" class="">
                    <span class="fn-13" style="font-weight: bold">{{item.name}}</span>
             </v-col>
          </v-row>
        </a>
         <template v-if="!formatDataTabel">

            <v-row >
              <v-col cols="12" align="left" class="">
                    <span class="color-gray-13">{{!item.barcode ? "" : "barcode" +" : "}} {{ item.barcode }} {{item.barcode && item.sku ? ",": "" }} {{!item.sku ? ""  : "SKU" + " : "}}  {{ item.sku }}</span>
              </v-col>
           </v-row>
            <v-row>
              <v-col cols="12" align="left" class="">
                    <span class="color-gray-13">
                       {{!item.productCode ? "" :  "รหัสสินค้า" + " : "}}  {{ item.productCode }}
                       {{item.productCode && ((item.prop1Description || item.prop2Description )) ? ",": "" }}
                       {{!item.prop1Description && !item.prop2Description ? "" : "ลักษณะ" + "  : "}}  {{ item.prop1Description }}  {{item.prop2Description }}
                    </span>
              </v-col>
            </v-row>

            <v-row >
              <v-col cols="12" align="left" class="">
                    <span class="color-gray-13">{{!item.cfCode ? "" : "รหัส CF" +" : "}} {{ item.cfCode }} {{item.cfCode && item.RackNo ? ",": "" }} {{!item.RackNo ? ""  : "RackNo" + " : "}}  {{ item.RackNo }}</span>
              </v-col>
            </v-row>
            
            <v-row v-if="item.brand!=null &&item.brand!=''">
              <v-col cols="12" align="left" class="">
                    <span class="color-gray-13">  {{ "Brand : "+item.brand }}</span>
              </v-col>
            </v-row>

          </template>

      </template>


      <template  v-slot:item.prop1="{ item }">
        <span class="fn-13">
          {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
        </span>
      </template>
      <template v-slot:item.quantity="{ item }">
        <span>
          {{formatMoney(item.quantity)}}
        </span>
      </template>
      <template v-slot:item.quantityBuffer="{ item }">
        <span>
          {{formatMoney(item.quantityBuffer)}}
        </span>
      </template>
      <template v-slot:item.quantityWaiting="{ item }">
        <span>
          {{formatMoney(item.quantityWaiting)}}
        </span>
      </template>
      <template v-slot:item.quantityWaitImport="{ item }">
        <span >
          {{formatMoney(item.quantityWaitImport)}}
        </span>
      </template>
      <template v-slot:item.quantityAvailable="{ item }">
        <span>
          {{formatMoney(item.quantityAvailable)}}
        </span>
      </template>
      <template v-slot:item.isActive="{ item }">
        <v-row justify="center">
          <a v-if="permission.ManageStock" @click="UpdateIsActive(dataset.indexOf(item),item)">
            <v-switch v-model="item.isActive" color="red" hide-details></v-switch>
          </a>
          <a v-else>
            <v-switch v-model="item.isActive" readonly color="red" hide-details></v-switch>
          </a>
        </v-row>
      </template>
      <template v-slot:item.remark="{ item }">
        <a @click="gotoStock_InFo(item)">
          <v-icon size="20" color="teal darken-3">fa-list</v-icon>
        </a>
      </template>
      <template v-slot:item.delete="{ item }">
        <a @click="DeleteStock(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
      </template>
    </v-data-table>




    <!-- Mobile hidden-sm-and-up -->
    <v-data-iterator
      :items="dataset"
      :items-per-page="9999999999"
      hide-default-header
      hide-default-footer
      class="hidden-sm-and-up"
    >
      <template v-slot:default="props">
        <v-row class="ma-2">
          <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1 mb-1">
            <v-card>
              <v-row class="py-2">
              <v-col cols="4" class="text-right" >
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                         <img :src="item.photoLink" class="picsize">
                        </a>
                         <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12"> No Picture</span>
                        </a>
              </v-col>
              <v-col cols="8"  class="pl-0">
          <v-row class="flex-column ma-0"  >

            <v-col class="px-0 py-2" >
              <div style="line-height: 1rem !important; ">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <b>
                      <div v-bind="attrs" v-on="on">
                        <span v-if="item.name != null" > {{ item.name }}</span>
                       </div>
                    </b>
                   </template>
                     <span v-if=" item.name != null">{{ item.name }}</span>
                 </v-tooltip>
               </div>
             </v-col>

            <!-- <v-col class="px-0 py-0" v-if="item.sku ||(item.prop1Description || item.prop2Description)" >
            <span v-if="item.sku" class="color-gray-12 " > SKU  : {{ item.sku }}</span>
            <span  v-if="item.sku && (item.prop1Description || item.prop2Description)" class="color-gray-12"> , </span>
            <span v-if="item.prop1Description || item.prop2Description"  class="color-gray-12">ลักษณะ :
              {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "")
             + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
            </span>
            </v-col> -->

            <v-col class="px-0 py-0" v-if="item.sku" >
            <span v-if="item.sku" class="color-gray-12 " > SKU  : {{ item.sku }}</span>
            </v-col>

            <v-col class="px-0 py-0" v-if="item.prop1Description || item.prop2Description" >
            <span v-if="item.prop1Description || item.prop2Description"  class="color-gray-12">ลักษณะ :
              {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "")
             + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
            </span>
            </v-col>

            <!-- <v-col class="px-0  py-0 " v-if="item.productCode || item.barcode" >
           <span  v-if="item.productCode " class="color-gray-12">รหัสสินค้า : {{ item.productCode }}</span>
           <span v-if="item.productCode && item.barcode"  class="color-gray-12"> , </span>
           <span v-if="item.barcode"  class="color-gray-12" > Barcode : {{ item.barcode }}</span>
            </v-col> -->

            <v-col class="px-0  py-0 " v-if=" item.barcode" >
           <span v-if="item.barcode"  class="color-gray-12" > Barcode : {{ item.barcode }}</span>
            </v-col>

            <v-col class="px-0  py-0 " v-if="item.productCode" >
           <span  v-if="item.productCode " class="color-gray-12">รหัสสินค้า : {{ item.productCode }}</span>

            </v-col>

            <v-col class="px-0  py-0" v-if="item.cfCode ">
           <span class="color-gray-12"> รหัส CF: {{ item.cfCode }}</span>
            </v-col>


          </v-row>
              </v-col>
              </v-row>


              <v-row class="packhai-showproduct-3 pa-2">
                <v-col cols="3" class="text-center">
                  <b>คงเหลือ</b>
                </v-col>
                <v-col cols="3" class="text-center">
                  <b>รอแพ็ค</b>
                </v-col>
                <v-col cols="3" class="text-center">
                  <b>รอนำเข้า</b>
                </v-col>
                <v-col cols="3" class="text-center">
                  <b>พร้อมขาย</b>
                </v-col>
                <v-col cols="3" class="text-center pa-2">
                  <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                    <b>
                      {{formatMoney(item.quantity)}}
                    </b>
                  </span>
                  <span v-else >
                    {{formatMoney(item.quantity)}}
                  </span>
                </v-col>
                <v-col cols="3" class="text-center pa-2">
                  <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                    <b>
                      {{formatMoney(item.quantityWaiting)}}
                    </b>
                  </span>
                  <span v-else >
                    {{formatMoney(item.quantityWaiting)}}
                  </span>
                </v-col>
                <v-col cols="3" class="text-center pa-2">
                  <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                    <b>
                      {{formatMoney(item.quantityWaitImport)}}
                    </b>
                  </span>
                  <span v-else >
                    {{formatMoney(item.quantityWaitImport)}}
                  </span>
                </v-col>
                <v-col cols="3" class="text-center pa-2">
                  <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                    <b>
                      {{formatMoney(item.quantityAvailable)}}
                    </b>
                  </span>
                  <span v-else >
                    {{formatMoney(item.quantityAvailable)}}
                  </span>
                </v-col>
              </v-row>
                <v-divider/>
              <v-row class="packhai-showproduct-3 pa-2" justify="center">

                            <v-col cols="6" class="text-center" >
                               <v-btn  @click="gotoStock_InFo(item)" outlined color="orange darken-3"  width="100%" class="pa-1">
                                <v-icon size="12" >fa-edit</v-icon>
                                <span > รายละเอียด</span>
                                  </v-btn>
                            </v-col>
                            <v-col cols="6" class="text-center">
                               <v-btn   @click="DeleteStock(item)" outlined color="red darken-3"  width="100%" class="pa-1">
                                <v-icon size="13" >fa-trash-alt</v-icon>
                                <span>ลบ</span>
                                  </v-btn>
                            </v-col>
              </v-row>

            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <!-- เปลี่ยนหน้า -->
    <v-row v-if="pageLength>1 && dataset.length>0" class="text-center pa-2 ma-0" @click="ChangePage()">
      <v-col cols="12" class="pa-0">

        <v-pagination
          v-model="page"
          :length="pageLength"
          :total-visible="pageTotalVisible"
          color="info"
        ></v-pagination>
      </v-col>
    </v-row>

    <div class="v-card-bottom-30" />
 


    <!--progress โหลดก่อนปริ้น  -->
    <div>
      <v-dialog v-model="progress_loading" persistent>
        <div class="text-center">
            <v-progress-circular
              :size="170"
              :width="7"
              color="error"
              indeterminate
          >กำลังเตรียมข้อมูล {{present_wait}}  / {{selectedStock.length}}

 </v-progress-circular>
        </div>
      </v-dialog>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import Loading from '@/website/components/Loading'
  import { stockService_dotnet, shopService_dotnet, branchService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { formatMoney, formatDate_thai, format_price, isNumberWithDot, isNumberWNoDot, DateNowNotNull, Trim_value, Trim_rules,UpdateViewTypePage,ViewTypePage,formatDate,parseDate} from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      // page
      BranchID:localStorage.getItem('Branch_BranchID'),
      page_loading: true,
      loading: false,
      progress_loading:false,
      editstock_dialog:false,
      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',
      staff_branchID:localStorage.getItem('Branch_StaffID') ,
      editstock: {
        shopID:null,
        id:null, 
        name:null,
        productCode:null,
        SKU:null,
        cfCode:null,
        barcode:null,
        stockquantity:null,
        ispackhai:false,
        buffer:null,
        prop1Description:null,
        prop2Description:null,
        rackNo:null,
        staffBranchId:null,
      },
      // ค้นหา
      search: {
        shopList: [{id: 0, name: "--ทั้งหมด--"}],
        shop: {id: 0, name: "--ทั้งหมด--"},
        "name": null,
        "productCode": null,
        "barcode": null,
        "sku": null,
       
        "warehouseList": [{"id": 0, "name": "--ทั้งหมด--"}],
        "showProductList": [{"id": 0, "name": "--ทั้งหมด--"},{"id": 1, "name": "สินค้าที่มีสต๊อก"},{"id": 2, "name": "สินค้าที่ไม่มีสต๊อก"}],
        "showProduct": {"id": 0, "name": "--ทั้งหมด--"},
        "isActiveList": [{"id": 0, "name": "--ทั้งหมด--"},{"id": 1, "name": "เปิดขาย"},{"id": 2, "name": "ไม่เปิดขาย"}],
        "isActive": {"id": 1, "name": "เปิดขาย"},
        "quantityCriteriaType" :{"id": 0, "name": "-- ยังไม่เลือก --"},
        "quantityWaitingCriteriaType" :{"id": 0, "name": "-- ยังไม่เลือก --"},
        "quantityType" :[ {"id": 0, "name": "-- ยังไม่เลือก --"}, {"id": 1, "name": "เท่ากับ"}, {"id": 3, "name": "น้อยกว่าหรือเท่ากับ"}, {"id": 2, "name": "มากกว่าหรือเท่ากับ"},],
        orderbyName: 'id',
        isAscending: false,
        warehouse:null,
      },

      // Export Excel
      loadingExcel: 0,
      datasetExcel: [],
      headerExcel: [
        { label: "No", field: "no", width: 10},
        { label: "ชื่อ", field: "name", width: 50},
        { label: "ลักษณะ1", field: "prop1", width: 40},
        { label: "คำอธิบายลักษณะ1", field: "prop1Description", width: 40},
        { label: "ลักษณะ2", field: "prop2", width: 40},
        { label: "คำอธิบายลักษณะ2", field: "prop2Description", width: 40},
        { label: "รหัสสินค้า", field: "productCode", width: 30},
        { label: "SKU", field: "sku", width: 30},
        { label: "Barcode", field: "barcode", width: 30},
        { label: "รหัส CF", field: "cfCode", width: 30},
        { label: "RackNo", field: "rackNo", width: 40},
        { label: "คงเหลือ", field: "quantity", width: 10},
        { label: "Buffer", field: "quantityBuffer", width: 10},
        { label: "รอแพ็ค", field: "quantityWaiting", width: 10},
        { label: "รอนำเข้า", field: "quantityWaitImport", width: 10},
         { label: "พร้อมขาย", field: "quantityAvailable", width: 10},
        // { label: "Active", field: "isActive", width: 10},
        { label: "น้ำหนัก", field: "weight", width: 10},
        { label: "กว้าง", field: "cubic1", width: 10},
        { label: "ยาว", field: "cubic2", width: 10},
        { label: "สูง", field: "cubic3", width: 10},
        { label: "ร้านค้า", field: "shopName", width: 100},
        { label: "คลัง", field: "warehouseName", width: 100},
        { label: "Brand", field: "brand", width: 100},
      ],

      // แสดงตาราง
      tableloading: false,
      total: 0,
      dataset: [],
      header: [
        { text: 'No',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'แก้ไข',align: 'center',sortable: false,value: 'editstock', icon: 0, width: '80px' ,labal:"แก้ไข"},
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0, width: '300px'},
        { text: 'คงเหลือ',align: 'right',sortable: false,value: 'quantity', icon: 0, width: '90px'},
        { text: 'Buffer',align: 'right',sortable: false,value: 'quantityBuffer', icon: 0, width: '90px'},
        { text: 'รอแพ็ค',align: 'right',sortable: false,value: 'quantityWaiting', icon: 0, width: '90px'},
        { text: 'รอนำเข้า',align: 'right',sortable: false,value: 'quantityWaitImport', icon: 0, width: '90px'},
        { text: 'พร้อมขาย',align: 'right',sortable: false,value: 'quantityAvailable', icon: 0, width: '100px'},
        { text: 'RackNo',align: 'center',sortable: false,value: 'rackNo', icon: 0, width: '120px'},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0, width: '80px'},
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode', icon: 0, width: '90px'},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0, width: '200px'},
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', icon: 0, width: '100px'},
        { text: 'รหัส CF',align: 'center',sortable: false,value: 'cfCode', icon: 0, width: '90px'},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0, width: '150px'},
        { text: 'คลัง',align: 'left',sortable: false,value: 'warehouseName', icon: 0, width: '150px'},
        { text: 'Brand',align: 'left',sortable: false,value: 'Brand', icon: 0, width: '90px'},
       
        // { text: 'Active',align: 'center',sortable: false,value: 'isActive', icon: 0, width: '80px'},
        { text: 'ลบ',align: 'center',sortable: false,value: 'delete', icon: 0, width: '60px' },
      ],
      warehouseList_filter:[],
      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 10,
      offsets: 0,

 
      dialogDetail: false,
      productSetItem: {},
      headerDetail: [
        { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px'},
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '70px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', width: '350px'},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', width: '250px'},
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', width: '110px'},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku'},
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode'},
        { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity', width: '90px'},
      ],
      datasetDetail: [],
      formatDataTabel:true,
      headers: [],

      selectItemFrom :
      {
          "warehouse": {"id": 0, "name": "--ทั้งหมด--"},
          selectItemByList :
          [{"id": 0, "name": "-- ยังไม่เลือก --","value": ""},
          {"id": 3, "name" : "ชื่อ","value": "name"},
          {"id": 12, "name": "รายละเอียด","value": "prop1"},
          {"id": 13, "name": "รหัสสินค้า","value": "productCode"},
          {"id": 14, "name": "รหัส CF","value": "cfCode"},
          {"id": 10, "name": "SKU","value": "sku"},
          {"id": 11, "name": "Barcode","value": "barcode"},
          {"id": 4, "name": "คงเหลือ","value": "quantity"},
          {"id": 5, "name": "Buffer","value": "quantityBuffer"},
          {"id": 6, "name": "รอแพ็ค","value": "quantityWaiting"},
          {"id": 7, "name": "รอนำเข้า","value": "quantityWaitImport"},
          {"id": 8, "name": "พร้อมขาย","value": "quantityAvailable"}],
          selectItemSortList : [ {"id": 0, "name": "-- ยังไม่เลือก --"}, {"id": 1, "name": "น้อยไปมาก"}, {"id": 2, "name": "มากไปน้อย"},],
          selectItemBy:{"id": 0, "name": "-- ยังไม่เลือก --"},
          selectItemSort:{"id": 0, "name": "-- ยังไม่เลือก --"},
      },
      selectedStock:[],
      count_print_data:0,
      count_print_data_no_barcode:0,


    }),
    computed: {
      showHeaders () {
      return this.headers.filter(s => this.headers.includes(s));
    },
      present_wait(){
        let num = this.count_print_data+ this.count_print_data_no_barcode;
        return  parseInt(num);
      },

    },
    watch: {
      'selectItemFrom.selectItemBy'(){
        if(!this.selectItemFrom.selectItemBy['id'] == 0 && !this.selectItemFrom.selectItemSort['id'] == 0)
        {
        this.OrderByCollum(this.selectItemFrom.selectItemBy['id'])
        }

      },
      'selectItemFrom.selectItemSort'(){
        if(!this.selectItemFrom.selectItemBy['id'] == 0 && !this.selectItemFrom.selectItemSort['id'] == 0)
        {
        this.OrderByCollum(this.selectItemFrom.selectItemBy['id'])
        }
      },
       selectedStock(){
          // console.log(this.selectedStock,"asdasd")
          //  print()
    }
    },
    async created() {
      document.title = this.$router.history.current.meta.title
      this.permission = JSON.parse(localStorage.getItem('permission'))

      this.GetShop()
      await this.GetWarehouse()
      // this.Load_Data(this.limits,this.offsets,this.search,1)
      // this.Load_Count(this.limits,this.offsets,this.search)
      // this.Set_Load_Data()

      // ส่วนของ สลับ row
      this.formatDataTabel = await this.ViewTypePage("my-stock");
      this.headers = [...this.header];

      this.changeFormat_DataTabel();
      this.SearchStock()
      this.page_loading = false

    },
    methods: {
      async GetShop() {
        let response = await axios.post(shopService_dotnet+'Shop/get-shop-list', {
          "shopType": 1,
          "branchId": localStorage.getItem('Branch_BranchID'),
        }, {headers: this.header_token})
        var shopList = response.data.result
        if (response.status==200 && shopList.length>0){
          for (var i in shopList){
            this.search.shopList.push({
              id: shopList[i].shop.id,
              name: shopList[i].shop.name,
            })
          }
        }
      },
      OpenDialogEditStock(item){
        this.editstock_dialog = true
        this.editstock.id = item.id
        this.editstock.shopID = item.shopID,
        this.editstock.name = item.name
        this.editstock.SKU = item.sku
        this.editstock.productCode = item.productCode
        this.editstock.cfCode = item.cfCode
        this.editstock.barcode = item.barcode  
        this.editstock.buffer = item.quantityBuffer
        this.editstock.rackNo = item.rackNo;
        this.editstock.prop1Description = item.prop1Description;
        this.editstock.prop2Description = item.prop2Description;
        this.editstock.staffBranchId=this.staff_branchID;
     
    
      },
      async GetWarehouse() {
        let response = await axios.get(branchService_dotnet+'Branch/get-warehouse-list?BranchID='+this.BranchID,   {headers: this.header_token})
        if (response.status ==200) {
          this.search.warehouseIDList=[];
          this.warehouseList_filter=[];
          if (response.data.warehouseList.length>0){
            if(response.data.warehouseList.length>1){
             
              this.warehouseList_filter = [{"id": 0, "name": "--ทั้งหมด--"}]
            }
            for (var i in  response.data.warehouseList){
              this.search.warehouseIDList.push(response.data.warehouseList[i].id)
              this.warehouseList_filter.push({
                "id": response.data.warehouseList[i].id,
                "name": response.data.warehouseList[i].name,
              })
            }
          }
          this.search.warehouse=this.warehouseList_filter[0];

        }
      },
      async SearchStock(){
        this.reSetSort()
        this.Load_Data(this.limits,0,this.search,1)
        this.Load_Count(this.limits,0,this.search)
        this.reSetIconHeader()
      },
      reSetSort(){
        this.search.orderbyName = 'id'
        this.search.isAscending = false
      },
      reSetIconHeader(){
        for (var i in this.header){
          this.header[i].icon = 0
        }
      },
      async ChangePage(){
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          var offsets = (this.page*this.limits)-this.limits
          this.Load_Data(this.limits,offsets,this.search,this.page)
          this.pageTemp = this.page
        }
      },
      async OrderByCollum(index){

        this.tableloading = true
        this.search.orderbyName = this.header[index].value
        this.search.isAscending = this.selectItemFrom.selectItemSort.id == 0 ? false :(this.selectItemFrom.selectItemSort.id == 1 ? true : false);
        // this.search.isAscending = this.header[index].icon==0 ? false : (this.header[index].icon==1 ? true : false)
        for (var i in this.header) {
          if (!(parseInt(index) == parseInt(i))){
            this.header[i].icon = 0
          }
        }
        // this.header[index].icon = this.header[index].icon==0 ? 1 : (this.header[index].icon==1 ? 2 : 1)
        await this.Load_Data(this.limits,this.offsets,this.search,this.page)
        this.tableloading = false
      },
      async Load_Data(limits,offsets,search,page){
        var searchTemp = {}
        searchTemp["name"] = (search.name==""? null:search.name)
        searchTemp["productCode"] = (search.productCode==""? null:search.productCode)
        searchTemp["barcode"] = (search.barcode==""? null:search.barcode)
        searchTemp["sku"] = (search.sku==""? null:search.sku)
        searchTemp["showProduct"] = search.showProduct.id==0 ? null : search.showProduct.id==1 ? true : false
        searchTemp["isActive"] =null
        searchTemp["brand"] =search.brand
        searchTemp["orderbyName"] = search.orderbyName
        searchTemp["isAscending"] = search.isAscending
        searchTemp["expire_date"] = !search.expire_date ?  null : search.expire_date+"T00:00:00.000Z"
        searchTemp["mfg_date"] = !search.mfg_date ? null: search.mfg_date+"T00:00:00.000Z"
        searchTemp["quantityCriteriaType"]  = search.quantityCriteriaType.id
        searchTemp["quantity"] = parseInt(search.quantity)
        searchTemp["quantityWaiting"] = parseInt(search.quantityWaiting)
        searchTemp["quantityWaitingCriteriaType"] = search.quantityWaitingCriteriaType.id

        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-stockshop-list-by-warehouse', {
          "branchId": this.BranchID,
          "shopId": search.shop.id==0 ? null : search.shop.id,
          "keyword": searchTemp,
          "isFromShop": false,
          "skip": offsets,
          "take": limits,
          "warehouseIDList":search.warehouse.id==0 ? search.warehouseIDList : [search.warehouse.id]
        }, {headers: this.header_token})
        .then( (res) => {
           this.tableloading = false
            if (res.status ==200) {
          this.loading = false
          this.page = page
          this.pageTemp = page
          this.offsets = offsets
          this.dataset = []
          this.dataset = res.data.resultData
        } else {
          this.loading = false
          this.AlertError()
        }
          }).catch((error) => {
            this.loading = false
            Swal.fire({
            icon: 'error',
            title: 'ทำรายการไม่สำเร็จ',
            text: 'กรุณาลองใหม่อีกครั้ง',
          })
            return null
         });


      },
      async handleUpdateStock(){ 
        this.loading = true
        
        let response = await axios.post(
          stockService_dotnet+"Stock/update-product-and-stock",
          { 
            shopID:this.editstock.shopID,
            barcode: this.editstock.barcode,
            productCode: this.editstock.productCode,
            cfCode: this.editstock.cfCode, 
            sku:this.editstock.SKU, 
            buffer:this.editstock.buffer === null || this.editstock.buffer === "" ? 0 : this.editstock.buffer,
            productMasterName:this.editstock.name,
            stockId:this.editstock.id,
            rackNo :this.editstock.rackNo,
            prop1Description : this.editstock.prop1Description,
            prop2Description : this.editstock.prop2Description,
            staffBranchId :this.editstock.staffBranchId
          },
          { headers: this.header_token }
        );
        if(response.data.status === "success"){
          this.loading = false
            
          this.editstock_dialog = false

          for(var item of this.dataset){
            if(item.id == this.editstock.id){
              item.name = this.editstock.name;
              item.sku = this.editstock.SKU;
              item.cfCode = this.editstock.cfCode;
              item.productCode = this.editstock.productCode;
              item.quantityBuffer = this.editstock.buffer;
              item.barcode = this.editstock.barcode;
              item.rackNo = this.editstock.rackNo;
              item.prop1Description = this.editstock.prop1Description;
              item.prop2Description = this.editstock.prop2Description;
            }
          }
          Swal.fire({
                    toast: true,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'top',
                    icon: 'success',
                    title: 'บันทึกสำเร็จ'
          })
        }
        else
        {
          this.loading = false
          Swal.fire({
              toast: true,
              timer: 3000,
              position: 'top',
              icon: 'error',
              title: response.data.message.th,
              showConfirmButton: false,
              })
        }
      },
      async Load_Count(limits,offsets,search){
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku
        searchTemp["showProduct"] = search.showProduct.id==0 ? null : search.showProduct.id==1 ? true : false
        searchTemp["isActive"] = null
        searchTemp["brand"] =search.brand
        searchTemp["expire_date"] = !search.expire_date ?  null : search.expire_date+"T00:00:00.000Z"
        searchTemp["mfg_date"] = !search.mfg_date ? null: search.mfg_date+"T00:00:00.000Z"
        searchTemp["quantityCriteriaType"]  = search.quantityCriteriaType.id
        searchTemp["quantity"] = parseInt(search.quantity)
        searchTemp["quantityWaiting"] = parseInt(search.quantityWaiting)
        searchTemp["quantityWaitingCriteriaType"] = search.quantityWaitingCriteriaType.id
        let response = await axios.post(stockService_dotnet+'Stock/get-stockshop-count-by-warehouse', {
          "branchId": localStorage.getItem('Branch_BranchID'),
          "shopId": search.shop.id==0 ? null : search.shop.id,
          "keyword": searchTemp,
          "isFromShop": false,
          "skip": offsets,
          "take": limits,
          "warehouseIDList":search.warehouse.id==0 ? search.warehouseIDList : [search.warehouse.id]
        }, {headers: this.header_token})
        if (response.status ==200) {
          this.total = response.data.resultCount
          this.pageLength =  (Math.ceil(response.data.resultCount/this.limits))
          if (this.pageLength<=0){this.pageLength=1}
        } else {
          this.loading = false
          this.AlertError()
        }
      },
      async DeleteStock(item){
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ลบ ใช่หรือไม่',
          text: item.name +' '+ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : ""),
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true
            let response = await axios.post(stockService_dotnet+'Stock/delete-stock', {
              "stockShopId": item.id,
            }, {headers: this.header_token})
            if (response.status==200 || response.status==204) {
              if (response.data.status==="success"){
                await this.SearchStock()
                this.loading = false
                this.AlertSuccess()
              }else{
                this.loading = false
                this.AlertWarning(response.data.text)
              }
            }else{
              this.loading = false
              this.AlertError()
            }
          }
        })
      },



      // สินค้าจัดเช็ต
      async Set_Load_Data(){
        var search = this.search
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku
        searchTemp["warehouseId"] = search.warehouse.id==0 ? null : search.warehouse.id
        searchTemp["warehouseIdList"] = []
        searchTemp["showProduct"] = search.showProduct.id==0 ? null : search.showProduct.id==1 ? true : false
        searchTemp["isActive"] = null
        searchTemp["expire_date"] = !search.expire_date ?  null : search.expire_date+"T00:00:00.000Z"
        searchTemp["mfg_date"] = !search.mfg_date ? null: search.mfg_date+"T00:00:00.000Z"
        searchTemp["quantityCriteriaType"]  = search.quantityCriteriaType.id
        searchTemp["quantity"] = parseInt(search.quantity)
        searchTemp["quantityWaiting"] = parseInt(search.quantityWaiting)
        searchTemp["quantityWaitingCriteriaType"] = search.quantityWaitingCriteriaType.id
        for (var k in this.search.warehouseList){
          if (parseInt(k)!=0){
            searchTemp.warehouseIdList.push(this.search.warehouseList[k].id)
          }
        }
        if (searchTemp.warehouseId!=null){
          searchTemp.warehouseIdList = []
          searchTemp.warehouseIdList.push(searchTemp.warehouseId)
        }
        
      },
      async ProductSetOfDetail(item){
        this.loading = true
        this.productSetItem = item
        await axios.all([
          axios.post(stockService_dotnet+'Stock/get-productset-vstockmaster-list', {
            'productMasterId': item.productMasterId,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-productset-list', {
            'productMasterId': item.productMasterId,
          }, {headers: this.header_token}),
        ])
        .then(axios.spread((productsetVStockMaster, productset) => {
          if (productsetVStockMaster.status==200 && productset.status==200){
            productsetVStockMaster = productsetVStockMaster.data
            productset = productset.data
            for (var k in productsetVStockMaster){
              for (var j in productset){
                if (productsetVStockMaster[k]["productMasterId"]==productset[j]["productMasterId"] && productsetVStockMaster[k]["id"]==productset[j]["productMasterItemId"]){
                  productsetVStockMaster[k]["quantity"] = parseInt(productset[j]["quantity"])
                }
              }
            }
            this.datasetDetail = productsetVStockMaster
            this.loading = false
            // this.dialogDetail = true
          }else{
            this.loading = false
            this.AlertError()
          }
        }));
      }, 
      UpdateStock(){
        var item = this.productSetItem
        var data = {
          "productMasterId": null,
          "productMasterItemId": null,
          "isProductSet": 0,
          "part_return": "/my-stock",
        }
        data.productMasterId = item.productMasterId
        data.productMasterItemId = item.productMasterItemId
        data.isProductSet = item.isProductSet ? 1 : 0
        localStorage.setItem('data', JSON.stringify(data))
        this.$router.push("/my-product/update")
      },


      // เรียกใช้
      AddProductSet(item){
        var data = {
          "productMasterId": null,
          "productMasterItemId": null,
          "isProductSet": 1,
        }
        localStorage.setItem('data', JSON.stringify(data))
        this.$router.push("/my-product/update")
      },
      async ConfirmUpdateIsActive(index,item){
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'เปลี่ยนสถานะ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {

            item.isActive = !item.isActive
            this.UpdateIsActive(index,item)

          }
        })
      },
      async UpdateIsActive(index,item){
        let response = await axios.post(stockService_dotnet+'Stock/update-isactive', {
          "stockShopId": item.id,
          "isActive": item.isActive,
        }, {headers: this.header_token})
        if (response.status==200) {
          // console.log("");
          // if (this.search.isActive.id!=0 && (this.search.isActive.id!=item.isActive)){
          //   this.dataset.splice(index,1)
          //   this.total = this.total-1
          // }
        }else{
          item.isActive = !item.isActive
          this.AlertError()
        }
      },
      async ConfirmUpdateIsActiveProductSet(index,item){
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'เปลี่ยนสถานะ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {

            item.isActive = !item.isActive
            this.UpdateIsActiveProductSet(index,item)

          }
        })
      },
      
      async ExportExcel(search){
        this.datasetExcel = []
        this.loadingExcel = 0
        Swal.fire({
          position: "top",
          icon: 'warning',
          text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async(result) => {
          if (result.value) {
            var searchTemp = {}
            searchTemp["name"] = (search.name==""?null:search.name)
            searchTemp["productCode"] = (search.productCode==""?null:search.productCode)
            searchTemp["barcode"] = (search.barcode==""?null:search.barcode)
            searchTemp["sku"] = (search.sku==""?null:search.sku) 
            searchTemp["showProduct"] = search.showProduct.id==0 ? null : search.showProduct.id==1 ? true : false
            searchTemp["isActive"] = search.isActive.id==0 ? null : search.isActive.id==1 ? true : false
            searchTemp["orderbyName"] = search.orderbyName
            searchTemp["isAscending"] = search.isAscending
            searchTemp["expire_date"] = !search.expire_date ?  null : search.expire_date+"T00:00:00.000Z"
            searchTemp["mfg_date"] = !search.mfg_date ? null: search.mfg_date+"T00:00:00.000Z"
            searchTemp["quantityCriteriaType"]  = search.quantityCriteriaType.id
            searchTemp["quantity"] = parseInt(search.quantity)
            searchTemp["quantityWaiting"] = parseInt(search.quantityWaiting)
            searchTemp["quantityWaitingCriteriaType"] = search.quantityWaitingCriteriaType.id
            var limits = 50
            var offsets = 0
            this.loadingExcel = 1
            var total = this.total
            var round = parseInt( total / limits)

            if ( limits * round < total ){
              round=round+1
            }

            var current = 0
            for (var i =1; i <=round; i++) {
              var data = await this.GetExportExcelProduct(limits,offsets,searchTemp)
              for (var j in data){
                this.datasetExcel.push(data[j])
                current += 1
              }
              this.loadingExcel = parseInt(parseFloat( i) / parseFloat(round) * parseFloat(100))
              offsets += limits
            }

            for (var d in this.datasetExcel){
              this.datasetExcel[d]["no"] = this.datasetExcel.length - parseInt(d)
            }

            this.loadingExcel = this.datasetExcel.length==0 ? 0 : 100
            document.getElementById("GetExcel").click();
          }
        })
      },
      async GetExportExcelProduct(limits,offsets,searchTemp){
        try {
          let stockList = await axios.post(stockService_dotnet+'Stock/get-stockshop-list-by-warehouse', {
            "branchId": localStorage.getItem('Branch_BranchID'),
            "shopId": this.search.shop.id==0 ? null : this.search.shop.id,
            "keyword": searchTemp,
            "isFromShop": false,
            "skip": offsets,
            "take": limits,
            "warehouseIDList":this.search.warehouse.id==0 ? this.search.warehouseIDList : [this.search.warehouse.id]
          }, {headers: this.header_token})
          if (stockList.status==200){
            return stockList.data.resultData
          }else{
            return []
          }
        }

        catch (e) {
          // console.log('error');
        }
      },
      gotoStock_InFo(item){
        window.open("stock-info?id="+item.id);
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      Letgo(text,item,isProductSet){
        if (text==="รายละเอียด" && !isProductSet){
          this.gotoStock_InFo(item)
        }else if (text==="ลบ" && !isProductSet){
          this.DeleteStock(item)
        }else if (text==="รายละเอียด" && isProductSet){
          this.ProductSetOfDetail(item)
        }else if (text==="ลบ" && isProductSet){
          this.DeleteProductSet(item)
        }
      },
      async changeFormat_DataTabel(){

        this.UpdateViewTypePage("my-stock",this.formatDataTabel); // ยิงอัพเดทสถานะบันทึกไว้

        let remove = ["SKU","Barcode","ลักษณะ","รหัสสินค้า","รหัส CF","Brand"]


        this.headers = [...this.header]

        if(this.formatDataTabel)
        {
          for(var item in remove)
          {
             let index =  this.headers.findIndex(x => x.text === remove[item])

             if(index != -1)
             {
              this.headers.splice(index, 1);
             }
          }

        }


        this.formatDataTabel = ! this.formatDataTabel;
      },
      async ontheClick(index){
        this.progress_loading= true
        this.count_print_data_no_barcode = 0 ;
        this.count_print_data = 0 ;
        setTimeout(() => this.printStock(index), 1500);
      },

     async printStock(index){

       let selectedStockBody = [...this.selectedStock];

        // เช็คว่า bacode เป็น null ไหม และ เก็บ ตำแหน่งจาก productMasterItemId
        const barcodeHasNull = selectedStockBody.filter(item => item.barcode === null).map(item => item.productMasterItemId);
        this.count_print_data = selectedStockBody.length -  barcodeHasNull.length;

        for(var ffe in this.count_print_data){
            this.count_print_data++;
        }

        // ทำการสร้าง barcode ที่มีค่าเป็น null
        for(const productMasterItemIdHasNull of barcodeHasNull){

        this.count_print_data_no_barcode++;

        // ยิง api ไปสร้าง barcode และ รอรับค่าไปใส่
        let barcodeNew = await this.generateBarcode(productMasterItemIdHasNull)

        //หาตำแหน่งของ productMasterItemIdHasNull ที่ต้องการไปนำ barcode ที่สร้างไปใส่
        const barcodeToChange =  selectedStockBody.find(item => item.productMasterItemId === productMasterItemIdHasNull);

        // ใส่ค่า barcode ใหม่ที่ได้มา
         if (barcodeToChange)
          {
            barcodeToChange.barcode = barcodeNew;
          }



        }

        //เก็บค่าที่เลือก ไว้ใน sessionStorage
        sessionStorage.setItem('selectedStockBody',JSON.stringify( this.selectedStock));

        // ส่ง productMasterItemId ไปผ่าน url ไปเช็ค
        const GenToURL = selectedStockBody.map(item => item.productMasterItemId).map(productMasterItemId => Number(productMasterItemId));


        let  query = JSON.stringify(GenToURL);
        let encodedQuery = encodeURIComponent(query);

        this.progress_loading= false

        if(index == "bacode")
        {
          window.open(`/my-stock/printBarcode?productMasterItemId=${encodedQuery}`, "myWindow", "width=1000,height=1000");
        }else{
          window.open(`/my-stock/printProductDetails?productMasterItemId=${encodedQuery}`, "myWindow", "width=1000,height=1000");
        }

      },

      async generateBarcode(ProductMasterItemID){

        // return "1111"
       axios.get(stockService_dotnet+'Stock/generate-barcode?ProductMasterItemID='+ProductMasterItemID,
          { headers: this.header_token})
          .then( (res) => {
             return res.data.barcode
          }).catch((error) => {
            Swal.fire({
            icon: 'error',
            title: 'ทำรายการไม่สำเร็จ',
            text: 'กรุณาลองใหม่อีกครั้ง',
          })
            return null
         });


      },


      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      formatMoney,
      formatDate_thai,
      isNumberWithDot,
      isNumberWNoDot,
      DateNowNotNull,
      Trim_value,
      Trim_rules,
      AlertSuccess,
      AlertWarning,
      AlertError,
      ViewTypePage,
      UpdateViewTypePage,
      formatDate,
      parseDate
    }
  }
</script>

<style scoped>
  >>>.v-select__slot{
    height: 40px;
  }
  .cut-text{
text-decoration: none;
text-overflow: ellipsis; /* เพิ่ม ... จุดจุดจุดท้ายสุด */
display: block; overflow: hidden;
white-space: nowrap;
width: 80%; /* กำหนดความกว้าง */
}

  .sort-bar{
    font-weight: 400;
    background: rgba(0,0,0,.03);
    padding: 0.8125rem 1.25rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .w-200P{
    width: 200px ;
  }
  .p-15{
    padding: 0px 0px 0px 15px;
  }
  .w-13r{
   width: 13rem;
  }

 >>>.v-dialog {
    overflow-y: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
  }


</style>
